import React from "react";
import PropTypes from "prop-types";

import AdBanner from "./AdBanner";
import AdCarousel from "./AdCarousel";
import AdPopup from "./AdPopup";
import AdModal from "./AdModal";
import EmptyContent from "./EmptyContent";

function AdContainer({ containerType, children, ...modalProps }) {
  if (containerType === "modal") {
    return <AdModal {...modalProps}>{children}</AdModal>;
  }

  return <AdPopup {...modalProps}>{children}</AdPopup>;
}

function Ads({
  adBannerData,
  onAdsPopUp,
  onAdIsBeingClicked,
  onNoAdsShowed,
  noContentMessage,
  ...renderProps
}) {
  const { show } = renderProps;

  if (!show) {
    return <div />;
  }

  if (adBannerData.length === 0) {
    onNoAdsShowed();
    return <EmptyContent show={show}>{noContentMessage}</EmptyContent>;
  }

  onAdsPopUp(adBannerData);
  return (
    <AdContainer {...renderProps}>
      <AdCarousel>
        {adBannerData.map((data) => (
          <AdBanner
            key={data.id}
            ad={{ ...data }}
            onBeingClicked={onAdIsBeingClicked}
          />
        ))}
      </AdCarousel>
    </AdContainer>
  );
}

Ads.propTypes = {
  title: PropTypes.string,
  noContentMessage: PropTypes.string,
  show: PropTypes.bool.isRequired,
  containerType: PropTypes.string,
  adBannerData: PropTypes.arrayOf(PropTypes.object).isRequired,
  onAdsPopUp: PropTypes.func,
  onAdIsBeingClicked: PropTypes.func,
  onNoAdsShowed: PropTypes.func,
};

Ads.defaultProps = {
  adBannerData: [],
  containerType: "modal",
  title: "DefaultTitle",
  show: false,
  noContentMessage: "No Content to show",
  onAdsPopUp: (ads) => console.log("onDefaultAdsPopUp"),
  onAdIsBeingClicked: (ad, e) => console.log(`clicked on ${ad.id}`),
  onNoAdsShowed: () => console.log("onDefaultNoAdsShowed"),
};

export default Ads;
