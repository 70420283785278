import React from "react";
import Modal from "react-bootstrap/Modal";

function AdModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>{props.children}</Modal.Body>
    </Modal>
  );
}

export default AdModal;
