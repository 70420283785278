import TagManager from "react-gtm-module";
import { AppEvents } from "./AppEvents";
import { LeadMessage, LeadAd } from "fullify-client";

type MetricData = {
  name: string;
  value?: any;
};

export default class GtmMetric implements AppEvents {
  onStart(): void {
    TagManager.dataLayer(metric({ name: "start", value: new Date() }));
  }
  onFinish(): void {
    TagManager.dataLayer(metric({ name: "finish", value: new Date() }));
  }
  onInfoUpdate(msg: LeadMessage): void {
    TagManager.dataLayer(
      metric({
        name: `info-${msg.data.name}`,
        value: msg.data.value,
      })
    );
  }
  onAdsShowedUp(ads: LeadAd[]): void {
    TagManager.dataLayer(metric({ name: "presentAds", value: ads.length }));
  }
  onAdGotClicked(ad: LeadAd): void {
    TagManager.dataLayer(
      metric({ name: "adClick", value: { id: ad.id, adValue: 30 } })
    );
  }
  onNoAdsShowed(): void {
    TagManager.dataLayer(metric({ name: "noAdsShowed" }));
  }
}

const metric = (data: MetricData) => {
  return {
    dataLayerName: "AppMetricLayer",
    dataLayer: {
      event: "FullifyEvent",
      fullify: {
        timestamp: new Date(),
        metric: { ...data },
      },
    },
  };
};
