import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { AppEnvConfig } from "./AppConfig";
import TagManager from "react-gtm-module";
import { Registry } from "./AppEvents";

TagManager.initialize({
  gtmId: AppEnvConfig.gtmId,
  dataLayerName: "AppMetricLayer",
});

Registry.subscribeAll();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
