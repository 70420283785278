import { LeadAd, LeadMessage } from "fullify-client";
import GtmMetric from "./GtmMetric";

export interface AppEvents {
  onStart(): void;
  onFinish(): void;
  onInfoUpdate(message: LeadMessage): void;
  onAdsShowedUp(ads: Array<LeadAd>): void;
  onAdGotClicked(ad: LeadAd): void;
  onNoAdsShowed(): void;
}

export class BaseAppEventListener implements AppEvents {
  onStart(): void {}
  onFinish(): void {}
  onInfoUpdate(message: LeadMessage): void {}
  onAdsShowedUp(ads: LeadAd[]): void {}
  onAdGotClicked(ad: LeadAd): void {}
  onNoAdsShowed(): void {}
}

class AppEventListener implements AppEvents {
  private handlers: Array<AppEvents> = [];

  public addListener(listener: AppEvents) {
    this.handlers.push(listener);
  }

  onStart(): void {
    this.handlers.forEach((h) => {
      this.runSafely(() => {
        h.onStart();
      });
    });
  }
  onFinish(): void {
    this.handlers.forEach((h) => {
      this.runSafely(() => {
        h.onFinish();
      });
    });
  }
  onInfoUpdate(message: LeadMessage): void {
    this.handlers.forEach((h) => {
      this.runSafely(() => {
        h.onInfoUpdate(message);
      });
    });
  }
  onAdsShowedUp(ads: LeadAd[]): void {
    this.handlers.forEach((h) => {
      this.runSafely(() => {
        h.onAdsShowedUp(ads);
      });
    });
  }
  onAdGotClicked(ad: LeadAd): void {
    this.handlers.forEach((h) => {
      this.runSafely(() => {
        h.onAdGotClicked(ad);
      });
    });
  }
  onNoAdsShowed(): void {
    this.handlers.forEach((h) => {
      this.runSafely(() => {
        h.onNoAdsShowed();
      });
    });
  }

  private runSafely(func: VoidFunction) {
    try {
      func();
    } catch (err) {
      console.info("Catch an error while trying to run ", func, err);
    }
  }

  private static instance: AppEventListener;
  private constructor() {}
  public static get Instance() {
    return this.instance || (this.instance = new this());
  }
}

export default AppEventListener.Instance;

export const Registry = {
  subscribeAll: () => {
    AppEventListener.Instance.addListener(new GtmMetric());
  },
};
