import React from "react";
import PropTypes from "prop-types";
import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";
import "./AdCarousel.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

function AdCarousel({ children }) {
  return (
    <Carousel
      responsive={responsive}
      swipeable={true}
      draggable={true}
      showDots={true}
      infinite={false}
      autoPlay={false}
      keyBoardControl={true}
      transitionDuration={500}
      containerClass="container-with-dots"
    >
      {children}
    </Carousel>
  );
}

AdCarousel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
};

AdCarousel.defaultProps = {
  children: [],
};

export default AdCarousel;
