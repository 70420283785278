import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "1%",
    padding: theme.spacing(1),
  },
}));

function AdPopup(props) {
  const { show, title } = props;
  const classes = useStyles();

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"lg"}
      open={show}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">{title}</DialogTitle>
      <DialogContent className={classes.root}>{props.children}</DialogContent>
    </Dialog>
  );
}

AdPopup.propTypes = {
  title: PropTypes.string.isRequired,
  show: PropTypes.bool,
};

AdPopup.defaultProps = {
  title: "NO TITLE",
  show: false,
};

export default AdPopup;
