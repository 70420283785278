import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "typeface-roboto";
import "./App.css";

import LandBot from "./components/Landbot";
import Ads from "./components/Ads";
import LeadManager from "./components/LeadManager";
import { LeadAd, LeadMessage } from "fullify-client";
import { AppEnvConfig } from "./AppConfig";
import EventDispatcher from "./AppEvents";

const leadManager = LeadManager.create();

const App = () => {
  const [isLandbotEnabled, setEnabledLandbot] = useState(true);
  const [ads, setAds] = useState<Array<LeadAd>>([]);

  const onInfoUpdate = (message: LeadMessage) => {
    console.log("Updating Lead info", message);
    leadManager.postingLeadData(message);
    EventDispatcher.onInfoUpdate(message);
  };

  const onBotFinish = async () => {
    setAds(await leadManager.revealAds());
    setEnabledLandbot(false);
    EventDispatcher.onFinish();
  };

  const onStart = () => EventDispatcher.onStart();
  const onAdsPopUp = (ads: LeadAd[]) => EventDispatcher.onAdsShowedUp(ads);
  const onNoAdsShowed = () => EventDispatcher.onNoAdsShowed();
  const onAdIsBeingClicked = (ad: LeadAd) => EventDispatcher.onAdGotClicked(ad);

  return (
    <div>
      <Ads
        title={AppEnvConfig.lookAndFeel.foundListingMsg}
        noContentMessage={AppEnvConfig.lookAndFeel.noListingFound}
        containerType="popup"
        adBannerData={ads}
        show={!isLandbotEnabled}
        onAdsPopUp={onAdsPopUp}
        onAdIsBeingClicked={onAdIsBeingClicked}
        onNoAdsShowed={onNoAdsShowed}
      />
      <LandBot
        landbotId={AppEnvConfig.landbotId}
        onInfoUpdate={onInfoUpdate}
        onStart={onStart}
        onFinish={onBotFinish}
        isEnabled={isLandbotEnabled}
      />
    </div>
  );
};

export default App;
