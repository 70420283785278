import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const styles = {
  card: {
    margin: "0 10px",
  },
  media: {
    height: 0,
    paddingTop: "80%", // 16:9
  },
};

function AdBanner({ classes, ad, linkButtonText, onBeingClicked }) {
  const { content, adLink } = ad;
  return (
    <Card className={classes.card}>
      <CardActionArea>
        <a
          href={adLink}
          onClick={(e) => onBeingClicked(ad, e)}
          style={{ textDecoration: "none" }}
          target="_blank"
          rel="noopener"
        >
          <CardMedia
            className={classes.media}
            image={content.featureImage.uri}
            title={content.title}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {content.title}
            </Typography>
            <Typography component="p">{content.description}</Typography>
          </CardContent>
        </a>
      </CardActionArea>
      <CardActions>
        <a
          href={adLink}
          onClick={(e) => onBeingClicked(ad, e)}
          style={{ textDecoration: "none" }}
          target="_blank"
          rel="noopener"
        >
          <Button size="small" color="primary">
            {linkButtonText}
          </Button>
        </a>
      </CardActions>
    </Card>
  );
}

AdBanner.propTypes = {
  ad: PropTypes.exact({
    id: PropTypes.string.isRequired,
    content: PropTypes.object.isRequired,
    adLink: PropTypes.string.isRequired,
  }).isRequired,
  onBeingClicked: PropTypes.func,
  linkButtonText: PropTypes.string,
};

AdBanner.defaultProps = {
  onBeingClicked: (ads, e) => console.log(`ad # ${ads.id} is being clicked`),
  linkButtonText: "Learn More",
};

export default withStyles(styles)(AdBanner);
