import { v4 as uuidv4 } from "uuid";
import { AdvertisementApi, LeadMessage } from "fullify-client";
import { ApiEnvConfig } from "../AppConfig";

class LeadManager {
  adsClient: AdvertisementApi;
  leadId: string;

  constructor(leadId: string) {
    this.adsClient = new AdvertisementApi(ApiEnvConfig);
    this.leadId = leadId;
  }

  static create() {
    return new LeadManager(getOrSetNew("fullifyLeadId", uuidv4()));
  }

  revealAds() {
    return this.adsClient.revealLeadAds({ id: this.leadId });
  }

  postingLeadData(leadMessage: LeadMessage) {
    return this.adsClient.postLeadMessage({ id: this.leadId, leadMessage });
  }
}

export function getOrSetNew(key: string, newValue: string) {
  const oldValue = localStorage.getItem(key);
  if (!oldValue) {
    localStorage.setItem(key, newValue);
    return newValue;
  }

  return oldValue;
}

export default LeadManager;
