import React, { useEffect } from "react";
import {
  LeadMessage,
  LeadMessageFromJSON,
  LeadMessageDataFromJSON,
  LeadMessageClientFromJSON,
} from "fullify-client";

import "./Landbot.css";

interface IframeEvent {
  origin: string;
  data: string;
}

type IframeProps = {
  landbotId: string;
  isEnabled: boolean;
  onEvent: (event: any) => void;
};

/**
 * Resolution for Lanbot recent changes of the iframe resource url.
 *
 * Returns the given input string in case is a url (starts with http)
 * or wrapped (old) version of the lanbot iframe url.
 *
 * @param input either a landbot id or a url src to be used by the iframe
 */
const resolveLandbotSrc = (input: string) =>
  input?.startsWith("http")
    ? input
    : `https://landbot.io/u/${input}/index.html`;

const LandbotIframe = ({ onEvent, landbotId, isEnabled }: IframeProps) => {
  useEffect(() => {
    function eventListener(event: IframeEvent) {
      if (event.origin.includes("landbot")) {
        try {
          var eventData = JSON.parse(event.data);
          if (eventData.type === "landbot-iframe") onEvent(eventData);
        } catch (err) {
          console.error("Unexpected Error:", event, err);
        }
      }
    }
    window.addEventListener("message", eventListener);
    return () => window.removeEventListener("message", eventListener);
  }, [onEvent]);

  return (
    <div>
      <iframe
        style={{ zIndex: isEnabled ? 999999 : 0 }}
        className="Landbot-iframe"
        src={resolveLandbotSrc(landbotId)}
        title="Landbot-iframe"
      />
    </div>
  );
};

const LandBot = (props: LanbotProps) => {
  const { onStart, onFinish, onInfoUpdate, landbotId } = props;

  const handler = (event: any) => {
    console.log("Processing landbot event", event);
    switch (event.name) {
      case "Data":
        onInfoUpdate(
          LeadMessageFromJSON({
            data: { ...LeadMessageDataFromJSON(event.value.data) },
            timestamp: Date.now(),
            client: {
              ...LeadMessageClientFromJSON({ id: `landbot-${landbotId}` }),
            },
          })
        );
        break;
      case "Action":
        switch (event.value.action) {
          case "Start":
            onStart();
            break;
          case "Finish":
            onFinish();
            break;
          default:
            console.log("Unhandled event action", event.value.action);
            break;
        }
        break;
      default:
        console.log("Unhandled event", event);
        break;
    }
  };

  return (
    <LandbotIframe
      onEvent={handler}
      landbotId={props.landbotId}
      isEnabled={props.isEnabled}
    />
  );
};

type LanbotProps = { landbotId: string } & typeof defaultProps;
const defaultProps = {
  isEnabled: true,
  landbotId: "DEFAULT-LANDBOT-ID", //Makes sure the value's type is string and not undefined
  onInfoUpdate: (message: LeadMessage) => {
    console.log("OnDefaultInfoUpdate", message);
  },
  onStart: () => {
    console.log("OnDefaultStart");
  },
  onFinish: () => {
    console.log("OnDefaultFinish");
  },
};
LandBot.defaultProps = defaultProps;

export default LandBot;
