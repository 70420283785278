import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
}));

function LinearIndeterminate() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LinearProgress />
      <LinearProgress color="secondary" />
    </div>
  );
}

function EmptyContent(props) {
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"lg"}
      open={props.show}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle style={{ textAlign: "center" }} id="max-width-dialog-title">
        {props.children}
      </DialogTitle>
      <DialogContent>
        <LinearIndeterminate />
      </DialogContent>
    </Dialog>
  );
}

EmptyContent.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string,
  ]),
};

EmptyContent.defaultProps = {
  show: false,
};

export default EmptyContent;
