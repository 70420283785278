import { Configuration } from "fullify-client";

export const ApiEnvConfig = new Configuration({
  basePath: process.env.REACT_APP_CONFIG_FULLIFY_API_BASE,
});

export const AppEnvConfig = {
  landbotId: process.env.REACT_APP_CONFIG_LANDBOT_ID,
  gtmId: process.env.REACT_APP_CONFIG_GTM_ID,
  lookAndFeel: {
    foundListingMsg: process.env.REACT_APP_CONFIG_FOUND_LISTINGS_MSG,
    noListingFound: process.env.REACT_APP_CONFIG_NOT_FOUND_LISTINGS_MSG,
  },
};
